import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { injectIntl } from "gatsby-plugin-intl"
import { IntlContextConsumer } from "gatsby-plugin-intl"

function SEO({ description, lang, meta, title, intl }) {
  const t = (id) => intl.formatMessage({ id })

  return (
    <IntlContextConsumer>
      {({ languages, lang: currentLocale }) =>(
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s | ${t('seo.description')}`}
          meta={[
            {
              name: `description`,
              content: description || t('seo.description'),
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: t('seo.og_description'),
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: t('seo.twitter_handler'),
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: t('seo.og_description'),
            },
          ].concat(meta)}
        />
      )}
    </IntlContextConsumer>
    
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default injectIntl(SEO)
